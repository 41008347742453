@use 'sass:math';
@import "variables";

//Used for old headers, but now replaced another style
//@mixin primary-hover($thickness) {
//  position: relative;
//  border-bottom: $thickness solid $primary;
//  &:before {
//    @include branding-flag-bottom($thickness * 4, $primary);
//  }
//}

@mixin branding-flag-bottom($size,$color) {
  position: absolute;
  content: '';
  bottom: -$size;
  left: 0;
  width: 0;
  height: 0;
  border-top: $size solid $color;
  border-right: $size solid transparent;
}

@mixin branding-flag-bottom-end($size,$color) {
  position: absolute;
  content: '';
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: $size solid $color;
  border-left: $size solid transparent;
}

@mixin branding-p($width,$height,$border-size: 10px,$border-color: white) {
  width: $width;
  height: $height;
  @each $color, $value in $theme-colors {
    &-#{$color}, &.branding-p-#{$color} {
      background: $value;
      &:before {
        @include branding-flag-bottom(math.div($height,2), $value);
      }
    }
  }
  &.with-border {
    border-right: $border-size solid $border-color;
    border-bottom: $border-size solid $border-color;
    &:after {
      position: absolute;
      content: '';
      top: $height;
      z-index: -1;
      left: 0;
      width: 0;
      height: 0;
      border-top: (math.div($width,2) + ($border-size - 5)) solid $border-color;
      border-right: (math.div($height,2) + ($border-size - 5)) solid transparent;
    }
  }
}

@mixin branding-cursor($width,$height,$color) {
  width: $width;
  height: $height;
  background: $color;
}

@mixin branding-cursor-blinking($width,$color) {
  border-right: $width solid $color;
  animation: blink-cursor 1s step-end infinite;
  animation-delay: 1s;
}

@mixin branding-caret($color) {
  caret-color: $color;
  caret-shape: block; /* not recognized in most browsers yet */
}

@mixin branded-link-styling($thickness) {
  color: $secondary !important;
  text-decoration: underline;
  text-decoration-color: $primary !important;
  text-decoration-thickness: $thickness !important;
  text-underline-offset: $thickness;
  &:hover {
    background: $primary;
    text-decoration: none !important;
  }
}

@mixin branded-top-bar($thickness) {
  padding-top: 15px;
  border-top: $thickness solid $primary;
}

@mixin review-card {
  @include block-link(1);
  @media (min-width: $desktop-start) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: $mobile-end) {
    margin-bottom: 2em;
  }
  &.pro_point {
    border-left: 3px solid $pro !important;
  }
  &.con_point {
    border-left: 3px solid $con !important;
  }
  &[onclick^='location'] { cursor: pointer; }
  background: #FFFFFF;
  &.card {
    border: 1px solid $gray-400;
    box-sizing: border-box;
    box-shadow: 0 20px 30px -16px rgba(0, 0, 0, 0.01), 0 6px 15px rgba(0, 0, 0, 0.01), 0 5px 9px -5px rgba(0, 0, 0, 0.02);
    border-radius: 3px;
  }
  padding: 0.8rem 1.2rem;
  .user-card {
    padding: 1rem 0;
    margin-bottom: 1rem;
  }
  .product {
    @include block-link(2);
    display: flex;
    align-items: center;
    padding: 1rem 0;
    line-height: 1.2;
    margin-bottom: 1rem;
    img { margin-right: 1rem; }
    border-bottom: 1px solid $gray-400;
    @media(min-width: $desktop-start) {
      height: 75px;
    }
    .name {
      font-weight: 400;
      color: $gray-900;
      font-size: 18px;
      @media(max-width: 1119px) and (min-width: $desktop-start) {
        font-size: 14px;
      }
      &:hover, &:focus-within {
        color: darken($gray-900, 20%);
        text-decoration: underline;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    .rating {
      margin-bottom: 20px;
      display: flex;
      .date {
        margin-left: auto;
        font-size: 14px;
        color: $gray-600;
      }
    }
    .title {
      color: $gray-900;
      font-size: 20px;
      margin-bottom: 20px;
      line-height: 1.2;
      &:not(.title-full) {
        @media(min-width: $desktop-start) {
          font-weight: 600;
          line-clamp: 2;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &.title-full {
        @media(min-width: $desktop-start) {
          line-clamp: 6;
          display: -webkit-box;
          -webkit-line-clamp: 6 !important;
          -webkit-box-orient: vertical;
          overflow: hidden;
          min-height: 140px !important;
          margin-bottom: 5px;
          font-size: 18px;
          line-height: 1.5;
        }
     }
      @media(max-width: 1119px) and (min-width: $desktop-start) {
        font-size: 16px;
        line-height: 1.3;
      }
    }
    .snippet {
      font-size: 16px;
      font-weight: 300;
      color: $gray-800;
      margin-bottom: auto;
      line-clamp: 6;
      display: -webkit-box;
      -webkit-line-clamp: 6 !important;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h4, h3 {
      font-size: 18px;
      font-family: $font-family-secondary;
      font-weight: 700;
      line-height: 1.3;
      padding: 20px 0 0;
    }
  }
}

@mixin user-card {
  display: flex;
  line-height: 1.2;
  img { object-fit: cover; /* for non-square images */ }
  @media(max-width: map-get($grid-breakpoints, "md")) {
    flex-wrap: wrap;
  }
  &-horizontal {
    align-items: center;
    @media(min-width: 1120px) {
      height: 95px;
    }
    @media(max-width: 1119px) and (min-width: $desktop-start) {
      height: 115px;
    }
    @media(max-width: map-get($grid-breakpoints, "xl")) and (min-width: map-get($grid-breakpoints, "md")) {
      word-break: break-word;
      .info {
        line-clamp: 5;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .labels {
      &:not(.label-block) {
        flex: 1 0 auto;
        @media(min-width: map-get($grid-breakpoints, "md")) {
          text-align: right;
        }
        @media(max-width: map-get($grid-breakpoints, "md")) {
          padding-top: 15px;
          flex-basis: 100%;
        }
      }
      &.label-block {
        padding-top: 15px;
        flex-basis: 100%;
      }
      .badge {
        margin-left: 5px;
      }
    }
  }
  &-vertical, &-vertical-compact {
    @include user-card-vertical;
  }
  &-vertical-compact {
    .labels {
      padding-top: 0.2rem;
    }
  }
  .user-details {
    display: flex;
    @include block-link(2 !important);
    width: 100%;
    align-items: center;

    &-horizontal {
      flex-direction: row;
      align-items: center;

      img {
        margin-right: 1rem;
      }

      .details {
        @media(max-width: map-get($grid-breakpoints, "sm")) {
          width: 80%;
        }
      }

      .name  {
        a {
          @include branded-link-styling(1px);
          margin-bottom: 6px;
        }
        :not(a) { color: $secondary; }
        font-size: 18px;
        word-break: break-all;
        @media(max-width: 1119px) and (min-width: $desktop-start) {
          font-size: 16px;
        }
      }

      .info {
        padding-top: 3px;
        line-height: 1.4;
        font-size: 12px;
      }
    }

    &-vertical-compact {
      .name a {
        font-weight: 400;
      }

      .info {
        font-size: 12px;
        padding-top: 3px;
      }
    }
  }

  .info {
    @include content-styling;
    color: $gray-600;
  }
}

@mixin user-card-vertical {
  width: 100%;
  @media(min-width: map-get($grid-breakpoints, "lg")) {
    flex-direction: column;
    align-items: flex-start;
  }
  .labels {
    padding-top: 2rem;
  }

  @media(max-width: map-get($grid-breakpoints, "lg")) {
    .labels {
      padding-top: 0.6rem;
    }
  }
  .user-details {
    @media(min-width: map-get($grid-breakpoints, "md")) {
      flex-direction: column;
      align-items: flex-start !important;
    }
    &.user-details-mobile-vertical {
      @media(max-width: map-get($grid-breakpoints, "md")) {
        flex-direction: column;
        align-items: center !important;
        .details {
          text-align: center;
          margin-top: 5px;
        }
      }
    }
    @media(max-width: map-get($grid-breakpoints, "md")) {
      img {
        margin-right: 1rem;
      }
    }

    .details {
      width: 100%;
      @media(min-width: map-get($grid-breakpoints, "md")) {
        margin: 10px 0;
        border-top: 1px solid $gray-400;
        padding-top: 10px;
      }
    }

    .name {
      > * {
        font-weight: 600;
        font-size: 16px;
        color: $secondary;
      }
      a {
        @include branded-link-styling(1px);
      }
    }


    .info {
      font-size: 12px;
      line-height: 18px;
      padding-top: 5px;
    }
  }
}

@mixin question-card {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-400;
  }
  &:not(:only-child) {
    padding: 1rem 0;
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    @media(min-width: map-get($grid-breakpoints, "lg")) {
      margin-left: 2.25rem;
    }
    @media(max-width: map-get($grid-breakpoints, "lg")) {
      .taggings { display: none; }
      //.title { order: 1 }
      //.date { order: 2 }
      //.stats { order: 3 }
    }
  }
  .info, .date {
    color: $gray-800;
    @include content-styling;
    font-size: 14px;
    i {
      margin-right: 5px;
    }
    @media(max-width: map-get($grid-breakpoints, "sm")) {
      font-size: 12px;
    }
  }
  .stats {
    display: flex;
    > * {
      margin-right: 1.8em;
    }
  }
  .taggings {
    font-size: 1.1em;
  }
  .title {
    flex-basis: 100%;
    padding: 1.5rem 0;
    a {
      color: $gray-900 ;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      &:hover {
        color: lighten($gray-900, 20%);
      }
      @media(max-width: map-get($grid-breakpoints, "sm")) {
        font-size: 18px;
      }
    }
  }
}

@mixin product-card {
  container: product / inline-size;
  width: 100%;
  .product-content {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    .product-logo {
      width: auto;
    }
    .product-details {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      //width: calc((100% / $grid-columns) * 11); // col-11
      //flex: 0 0 auto;
      padding-left: 12px;
      text-align: left;
      @include content-styling;
      > * {
        white-space: normal;
      }
    }
    &.vertical {
      @include product-card-vertical;
    }
    &.two-column-layout {
      @include product-card-multi-column;
    }
    &:not(.two-column-layout) {
      @container product (max-width: 170px) {
        /* display as vertical when the product is smaller than a mobile device */
        @include product-card-vertical;
      }
    }
  }
  &.product-block-link {
    @include block-link(1);
  }
  &.card {
    padding: 1.2em;
  }
  .product-name {
    &.row {
      height: 65px;
    }
    font-weight: 700;
    color: $black;
    font-size: 17px;
    line-height: 1.2;
    &.name-sm { font-size: 14px; font-weight: normal; }
    @media (min-width: $desktop-start) {
      text-align: left;
      &.name-lg { font-size: 24px; }
    }
    @media(max-width: map-get($grid-breakpoints, "xl")) and (min-width: map-get($grid-breakpoints, "sm")) {
      font-size: 14px;
    }
    @media(max-width: $mobile-end) {
      height: auto;
      overflow: hidden;
    }
    @media(max-width: 477px) {
      font-size: 13px;
      height: auto;
      &.name-lg { font-size: 16px; }
    }
  }
  .flashy-stats {
    > :not(:first-child) {
      border-left: 1px solid $border-color;
      padding-left: 10px;
    }
  }
  .stats {
    @include content-styling;
    font-size: 14px;
    color: $gray-700;
    @media(max-width: 477px) {
      font-size: 11px;
    }
  }
  .product-badge {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 80px solid $yellowlight;
    border-right: 80px solid transparent;
    left: 0;
    top: 0;
    span {
      position: absolute;
      width: 80px;
      transform: rotate(-45deg);
      text-align: center;
      top: -80px;
      left: -20px;
      //line-height: 1.2;
    }
  }
}

@mixin product-card-vertical {
  flex-wrap: wrap;
  .product-logo {
    width: 100%;
    text-align: center;
  }
  .product-details {
    align-items: center;
    text-align: center;
    width: 100%;  // do I need this?
    padding: 0 !important;

    > * {
      text-align: center;
      align-self: center;
      margin: 0 0 0.5rem;
      &:first-child {
        margin-top: 0.8rem;
      }
    }
  }
}

@mixin product-card-multi-column {
  align-items: stretch;
  .product-logo {
    padding-right: 12px;
  }
  .product-details {
    width: calc((100% / $grid-columns) * 7); // col-7
    align-items: flex-start;
    text-align: left;
    @media(min-width: map-get($grid-breakpoints, "md")) {
      border-right: 1px solid $gray-300;
      padding-right: 1.5rem;
      margin-right: 1.5rem;
    }
  }
  @media(max-width: map-get($grid-breakpoints, "md")) {
    @include product-card-vertical;
    .product-details {
      > * {
        align-self: flex-start;
        text-align: left;
      }
    }
  }
}

@mixin comparison-card {
  container: comparison-card / inline-size;
  padding: 1em !important;
  &:not(.vertical) {
    .product-col {
      // extra styling attributes
      position: relative;

      &:not(.vertical-comparison) {
        &:not(:first-child) {
          border-left: 1px solid $gray-400;
          @container comparison-card (min-width: 540px) {
            padding-left: 1.5rem;
          }
          &:before {
            @include comparison-vs;
          }
        }
        .product-card .product-content {
          @container comparison-card (max-width: 540px) {
            .product-logo img {
              max-width: 40px;
              max-height: 40px;
            }
          }
        }
      }
    }
  }
  &:not(.comparison-widget) {
    &:not(.vertical) {
      .product-col {
        .product-card .product-content {
          @container product (max-width: 240px) {
            /* display as vertical when the product is smaller than a mobile device */
            @include product-card-vertical;
          }
        }
      }
    }
    .product-col {
      &.vertical-comparison {
        @media (min-width: $desktop-start) {
          padding: 20px 0;
          &:first-child { padding-top: 0; }
          width: 100%;
          flex: 0 0 auto;
          margin: 0 0.75rem;
          &:not(:first-child) {
            border-top: 1px solid $gray-500;
            position: relative;
            &:before {
              @include comparison-vs-vertical;
            }
          }
        }
        @media (max-width: $desktop-start) {
          &:not(:first-child) {
            border-left: 1px solid $gray-400;
            &:before {
              @include comparison-vs;
            }
          }
        }
      }
    }
  }
  &.comparison-widget {
    .comparison-row {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      > .product-col {
        width: calc((100% / $grid-columns) * 5); // col-5
        padding-right: 12px;
        .btn:active { border: 0; }
      }
      .comparison-go {
        width: calc((100% / $grid-columns) * 2); // col-2
      }
      @container comparison-card (max-width: 540px) {
        flex-wrap: wrap;
        > .product-col {
          width: calc((100% / $grid-columns) * 6); // col-6
        }
        .comparison-go {
          width: 100%;
          margin-top: 12px;
        }
      }
    }
    @container comparison-card (max-width: map-get($container-max-widths, "sm")) {
      .dropdown-toggle:has(.product-card) {
        justify-content: center;
      }
      .dropdown-item .product-card > * {
        flex-direction: row !important;
        flex-wrap: nowrap !important;
      }
    }
    &.vertical {
      .comparison-row {
        flex-wrap: wrap;
        > * {
          width: 100%;
          padding: 0;
        }
        .comparison-go {
          margin-top: 12px;
        }
      }
      .product-col.vertical-comparison {
        position: relative;
        &:first-child { padding-top: 0; }
        &:not(:has(.dropdown-toggle .product-card)) {
          padding: 20px 0;
        }
        width: 100%;
        flex: 0 0 auto;
        &:not(:first-child) {
          border-top: 1px solid $gray-500;

          &:before {
            @include comparison-vs-vertical;
          }
        }
      }
      .dropdown-toggle {
        justify-content: center;
        white-space: normal;
        .product-card {
          div {
            display: flex;
            justify-content: center;
            //width: auto;
          }
        }
      }
    }
    .product-col:not(.vertical-comparison) {
      .dropdown-toggle {
        white-space: normal;
        &:not(.btn-light) { padding: 0;  }
      }
      @container comparison-card (max-width: 540px) {
        position: static;
        .dropdown { position: static; }
        .dropdown-menu { top: 50% !important; min-width: 250px; }
        .dropdown-item {
          .product-card .name { font-size: 14px; }
        }
        &:not(:first-child) {
          &:before {
            content: '';
          }
          .dropdown-toggle {
            position: relative;
            &:before {
              @include comparison-vs;
              left: -33px;
              opacity: 1;
              font-weight: 400;
            }
          }
        }
      }
      &.vertical {
        margin: 0;
      }
    }
  }
}

@mixin one-line-ellipsis {
  // Add fixed width where this is added
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin comparison-vs {
  // side by side comparison
  position: absolute;
  content: 'Vs';
  left: -8px;
  background: $white;
  color: $gray-700;
  font-size: 14px;
  padding: 0.25em 0;
  @media (min-width: $desktop-start) {
    top: 30%;
  }
  @media (max-width: $mobile-end) {
    top: 50%;
  }
}

@mixin comparison-vs-vertical {
  // vertical comparison
  position: absolute;
  content: 'Vs';
  background: $white;
  color: $gray-700;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 0.35em;
  top: -10px !important;
  width: 40px;
  text-align: center;
  line-height: 1;
}

@mixin content-styling {
  font-weight: 300;
  letter-spacing: 0.3px;
}

@mixin typewriter {
  overflow: hidden;
  border-right: 10px solid $white; /* The typwriter cursor */
  white-space: nowrap;
  animation:
          typing 4.5s steps(35, end) forwards,
          blink-cursor 1s step-end infinite;
  animation-delay: 1s;
}

@mixin list-links {
  a {
    display: block;
    padding-bottom: 20px;
    color: $gray-700;
    &:hover:not(.disabled) {
      color: darken($gray-700, 15%);
    }
    &.disabled {
      color: lighten($gray-700, 20%);
    }
    &[data-count] {
      position: relative;
      padding-right: 40px;
      &:after {
        content: '(' attr(data-count) ')';
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  &.list-compact {
    a {
      padding-bottom: 10px;
    }
  }
}

@mixin autocomplete {
  .autocomplete-suggestions {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px #00000029;
    overflow: auto;
    top: 100%;
    left: 0;
    width: 100% !important;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: left;
    @media (min-width: $desktop-start) {
      margin-top: 8px;
    }

    &::-webkit-scrollbar {
      max-width: 5px;
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
      background-color: $gray-400;
      border-radius: 10%;
    }

    /* Track */

    &::-webkit-scrollbar-track {
      background-color: $gray-100;
    }

    .autocomplete-suggestion {
      font-size: 15px;
      cursor: pointer;
      line-height: 1.2;
      color: $gray-900;
      &:not(:has(a)) {
        padding: 5px 15px;
      }
      a {
        display: block;
        width: 100%;
        padding: 5px 15px;
        color: $gray-900;
        &:hover {
          background: $gray-200;
        }
      }
      strong {
        font-weight: 600;
        color: $gray-900;
      }
    }

    .autocomplete-selected {
      background: $gray-200;
    }

    .autocomplete-group {
      padding: 10px 15px;
      color: $gray-700;
      text-transform: uppercase;
      font-size: 13px;
      strong {
        display: block;
        border-bottom: 1px solid $gray-400;
      }
      &:not(:first-of-type) {
        border-top: 1px solid $gray-400;
        margin-top: 10px;
        padding-top: 20px;
      }
      &:empty {
        padding-top: 0;
      }
    }
  }
}

@mixin search-form {
  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  input {
    color: $gray-900;
    font-family: $font-family-secondary;
    font-weight: 400;
    box-sizing: border-box;
    border: 0;
    background: transparent;
    flex-grow: 1;
    font-size: 16px;
    &::placeholder {
      color: $gray-700;
    }
    &:focus-visible {
      outline: none;
    }
  }
  .search-submit {
    font-size: 20px;
    cursor: pointer;
  }
  .search-close {
    color: $gray-900;
    font-size: 16px;
    cursor: pointer;
    margin-right: 1em;
  }
  @include autocomplete;
}

@mixin block-link($z) {
  position: relative;
  a {
    &.block-link {
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $z;
        content: ' ';
        cursor: pointer;
      }
    }
    &:not(.block-link), &[onclick^='location'] {
      position: relative;
      z-index: $z + 1;
    }
  }

  :is(a:not(.block-link), div) [data-bs-toggle="tooltip"], i {
    position: relative;
    z-index: $z + 1;
  }
}

@mixin comment-form {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  position: relative;
  form {
    width: 100%;
    margin-left: 15px;
    box-sizing: border-box;
    .embedded-comment-bottom {
      display: none;
      margin-top: 15px;
    }
    ul.parsley-errors-list, .errors-box { display: none; }
    .redactor-styles {
      overflow: hidden;
      font-family: $font-family-secondary;
      height: 55px;
      transition: all 0.3s ease-out;
      p { font-family: $font-family-secondary; }
    }
    &:focus-within {
      .redactor-styles {
        height: 200px;
        overflow: auto;
      }
      .embedded-comment-bottom { display: block; }
      ul.parsley-errors-list, .errors-box {
        display: list-item;
        top: 113%;
        position: absolute;
        left: 0;
        margin: 0;
        font-family: $font-family-secondary;
        font-size: 12px;
        // hide stubborn bullet:
        padding: 0 !important;
        overflow: hidden;
        li { margin: 0; }
      }
      .errors-box {
        top: 85%;
        left: 80px;
        color: $reddark;
      }
    }
    fieldset {
      border: 1px solid $gray-400;
      margin-top: 15px;
      legend {
        padding: 0 8px;
        color: $gray-900;
      }
    }
  }
  @media(max-width: map-get($grid-breakpoints, "sm")) {
    padding: 0.5rem;
    img, .css-avatar { display: none; }
    form {
      margin-left: 0;
      .btn { flex-basis: 100%;  }
      ul.parsley-errors-list, .errors-box {
        top: 103% !important;
      }
    }
  }
}

@mixin comment-card {
  display: flex;
  flex-direction: column;
  > * { flex-basis: 100%; }
  border: 1px solid $gray-400;
  border-radius: 5px;
  background: $white;
  padding: 20px;
  margin: 20px 0;
  font-size: 15px;
  line-height: 1.7;
  position: relative;
  .featured {
    position: absolute;
    top: -10px;
    right: -7px;
   }
  .user-card { padding: 0; margin: 0; height: auto; }
  @media(max-width: map-get($grid-breakpoints, "lg")) { .badge { display:none; }}
  @include hide-empty-nodes;
  .comment-body {
    @include content-styling;
    p {
      font-family: $font-family-secondary !important;
      margin: 20px 0 0;
      padding: 0;
      &:first-child { margin: 0; }
      word-wrap: break-word;
    }
    font-size: 18px;
    color: $secondary;
    line-height: var(--bs-body-line-height);
    [class*="gitb-expander"] {
      &:after {
        font-weight: 600;
        font-size: 16px;
        font-family: $font-family-secondary;
        bottom: -5px;
        right: auto;
        @include branded-link-styling(1px);
        text-underline-offset: 0;
      }
      &:hover::after {
        background: $primary;
        text-decoration: none !important;
      }
    }
  }
  &.reply {
    border: none;
    padding: 0;
    position: relative;
    padding-left: 60px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      content: "\e3d6";
      position: absolute;
      top: 3px;
      left: 0;
      color: $gray-400;
      font-size: 30px;
      line-height: 1;
    }
    @media(max-width: map-get($grid-breakpoints, "sm")) {
      .user-details-horizontal { align-items: flex-start; }
      &:before { font-size: 25px; top: 0; }
      padding-left: 50px;
    }
    &:last-of-type {
      .comment-body {
        border-bottom: none;
      }
    }
  }
  a[data-gitb-tagged] {
    color: $secondary;
    text-decoration: underline;
    text-decoration-color: $primary;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    cursor: pointer;
    &:hover {
      background-color: $primary;
    }
  }
  .open-reply-form.open {
    position: relative;
    color: transparent !important;
    &:before {
      color: $secondary;
      font-weight: bold;
      position: absolute;
      left: 18px;
      content: 'Close'
    }
    &:hover:before {
      color: $blue-hover;
    }
  }
}

@mixin hide-empty-nodes {
  p:empty, :is(ul, ol, li, p) br:first-child, :is(ul,ol,p,br,li) + br  { display: none; }
  p:empty + p { margin-top: 0; }
}

@mixin border-between {
  > [class*='col-'] {
    position: relative;
    &:not(:last-child) {
      //@media(min-width: map-get($grid-breakpoints, "sm")) {
      //  padding-right: 2rem;
      //}

      &:after {
        background: $border-color;
        width: $border-width;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }

    @media(min-width: map-get($grid-breakpoints, "sm")) {
      padding: 0 2rem;
    }
  }
  // disable border for last child in each row, where the columns wrap themselves
  @media(min-width: map-get($grid-breakpoints, "lg")) {
    > .col-lg-4 {
      &:nth-child(3n) {
        &:after { width: 0; background: unset; }
      }
    }
    > .col-lg-6 {
      &:nth-child(2n) {
        &:after { width: 0; background: unset; }
      }
    }
  }
  @media(min-width: map-get($grid-breakpoints, "md")) {
    > .col-md-4 {
      &:nth-child(3n) {
        &:after { width: 0; }
      }
    }
    > .col-md-6 {
      &:nth-child(2n) {
        &:after { width: 0; }
      }
    }
  }
  @media(min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "md")) {
    > .col-sm-4 {
      &:nth-child(3n) {
        &:after { width: 0; }
      }
    }
    > .col-sm-6 {
      &:nth-child(2n) {
        &:after { width: 0; }
      }
    }
  }
}

@mixin report-card {
  border: 1px solid $gray-400;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0;
  container: report-banner / inline-size;
  @container report-banner (min-width: 795px) {
    & > div {
      flex-wrap: nowrap;
      & > div:nth-child(1) {
        flex-basis: percentage(divide(3, 12));
      }
      & > div:nth-child(2) {
        flex-basis: percentage(divide(9, 12));
        font-size: 18px;
        b { display: block; }
      }
    }
  }
  @container report-banner (min-width: 575px) and (max-width: 795px) {
    & > div {
      flex-wrap: nowrap;
      & > div:nth-child(1) {
        flex-basis: percentage(divide(4, 12));
      }
      & > div:nth-child(2) {
        flex-basis: percentage(divide(8, 12));
      }
    }
  }
  @container report-banner (max-width: 575px) {
    //& > div > div  {
    //  width: 100%;
    //  text-align: center;
    //  div:has(.btn) {
    //    align-self: center;
    //  }
    //}
    .report_image {
      @include report-single-thumb-tiny;
    }
    & > div {
      flex-wrap: nowrap;
      & > div:nth-child(1) {
        flex-basis: percentage(divide(5, 12));
      }
      & > div:nth-child(2) {
        flex-basis: percentage(divide(7, 12));
      }
      @include lead-gen;
    }
  }
  @media(max-width: 665px) {
    border: none;
    padding: 0;
    background: none !important;
  }
}

@mixin report-single-thumb {
  //background: right url(../../images/reports/thumbnail_bg.png) no-repeat;
  background: right url('https://images.peerspot.com/image/upload/f_auto,q_auto/v1707635957/reports/thumbnail_bg.png') no-repeat;
  max-width: 194px;
  box-shadow: 12px 12px 25px 0 rgba(50, 50, 50, 0.3);
  padding-top: min(137%, 271px); // aspect ratio
  width: 100%;
  background-size: cover;
  margin: 0 auto 20px;
  justify-self: center;
  .report-inner-wrapper {
    padding: 65px 15px 10px;
  }
  .report-title {
    font-size: 1.3rem;
    &-md { font-size: 1rem;  line-height: 1.4; }
    &-sm { font-size: 0.8rem; line-height: 1.4;  margin: 3px 0; }
    @media(min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "lg")) {
      font-size: 1.1rem;
      &-md { font-size: 0.7rem;  line-height: 1.4; }
      &-sm { font-size: 0.7rem; line-height: 1.4;  margin: 3px 0; }
    }
  }
  .report-subtext {
    font-size: 14px;
    @media(min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "lg")) {
      font-size: 0.7rem;
    }
  }
  &:after {
    content: 'PDF';
    position: absolute;
    top: 15px;
    right: -5px;
    width: 55px;
    height: 30px;
    line-height: 1;
    font-weight: 600;
    font-size: 1.3rem;
    background-color: $reddark;
    color: white;
    padding: 5px;
    text-align: center;
  }
  &.report-bundle {
    &-2 {
      flex: 0 0 auto;
      &:first-child {
        transform: rotate(-10deg);
      }
      &:last-child {
        transform: rotate(10deg);
        @media (min-width: map-get($grid-breakpoints, "md")) {
          margin-left: -100px;
        }
        @media (max-width: map-get($grid-breakpoints, "md")) {
          margin-left: -30vw;
        }
      }
    }
    &-3 {
      flex: 0 0 auto;
      overflow-x: hidden;
      &:nth-child(1) {
        transform: rotate(-10deg);
        margin-right: -150px;
      }
      &:nth-child(2) {
        margin-top: -30px;
        @media (min-width: map-get($grid-breakpoints, "md")) {
          margin-right: -80px;
          &:after {
            display: none;
          }
        }
        @media (min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "md")) {
          margin-right: auto;
        }
        @media (max-width: map-get($grid-breakpoints, "sm")) {
          margin-right: -80px;
        }
      }
      &:nth-child(3) {
        transform: rotate(10deg);
        overflow-x: visible;
        margin-top: 20px;
        @media (min-width: map-get($grid-breakpoints, "md")) {
          margin-left: -40px;
        }
        @media (min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "md")) {
          margin-left: -20vw;
        }
        @media (max-width: map-get($grid-breakpoints, "sm")) {
          margin-left: -40px;
        }
      }
    }
  }
}

@mixin report-single-thumb-tiny {
  margin: 0 !important;
  .report-inner-wrapper {
    padding: 35px 8px 5px !important;
  }
  .report-title, .report-subtext {
    font-size: 0.6rem !important;
    line-height: 1.3;
    margin: 0;
  }
  .ps-logo {
    margin-top: 1%;

    img {
      height: 15px;
      width: auto;
    }
  }
  &:after {
    width: 30px !important;
    height: 15px !important;
    font-weight: 400 !important;
    font-size: 0.7rem !important;
    padding: 2px !important;
  }
  @media(min-width: 410px) {
    .report-title, .report-subtext {
      font-size: 0.9rem !important;
      line-height: 1.4;
    }
    &:after {
      width: 35px;
      height: 22px;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 4px;
    }
  }
}

@mixin report-triple-thumb {
  background: center url(../../images/reports/triple_thumbnail_bg.webp) no-repeat;
  //background: center url('https://images.peerspot.com/image/upload/f_auto,q_auto/v1707635923/reports/triple_thumbnail_bg.png') no-repeat;
  padding-top: min(137%, 310px); // aspect ratio
  align-items: flex-end;
  width: min(100%, 416px);
  background-size: min(100%, 416px);
  margin: 0 auto;
  .report-inner-wrapper {
    max-width: 160px;
    transform: rotate(10deg);
    padding: 100px 15px 50px;
    margin-right: 30px;
    /* Fixes for specific points where the text overlay breaks */
    @media (min-width: map-get($grid-breakpoints, "md")) and (max-width: 865px), (min-width: map-get($grid-breakpoints, "lg")) and (max-width: 1050px) {
      max-width: 150px;
      padding: 100px 10px 60px;
      margin-right: 25px;
      &:has(.report-title-sm) {
        max-width: 145px;
        margin-right: 30px;
      }
      &:has(.report-title-md) {
        margin-right: 23px;
      }
      .report-title {
        &-md { font-size: 0.8rem; line-height: 1.3;  margin: 3px 0; }
        &-sm { font-size: 0.7rem; line-height: 1.3;  margin: 3px 0; }
      }
    }
  }
  .report-title {
    font-size: 1rem;
    &-md { font-size: 0.9rem;  line-height: 1.4; }
    &-sm { font-size: 0.8rem; line-height: 1.4;  margin: 3px 0; }
  }
  .report-subtext {
    font-size: 12px;
  }
}

@mixin lead-gen {
  position: relative;
  font-size: 12px;
  .btn {
    padding: 0.5rem 1.1rem;
    i { display: none; }
    font-size: 14px !important;
    background: $primary;
    color: $secondary;
    border: none;
    margin: 0 !important;
    &:hover {
      background: darken($primary, 5%);
    }
  }
  & > * { z-index: 999; }
  .container-sm-none {
    display: none;
  }
  &:after {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: -10px;
    right: -10px;
    content: ' ';
    background: $yellowlight;
    border-bottom: 1px solid $gray-400;
  }
}
@mixin report-image {
  box-sizing: border-box;
  font-family: $font-family-secondary;
  background-color: none;
  position: relative;
  &:not(.report-triple-thumb) {
    @include report-single-thumb;
  }
  &.report-triple-thumb {
    @media(max-width: map-get($grid-breakpoints, "sm")) {
      @include report-single-thumb;
      @include report-single-thumb-tiny;
    }

    @media(min-width: map-get($grid-breakpoints, "sm")) {
      @include report-triple-thumb;
    }
  }
  .report-inner-wrapper {
    position: absolute;
    text-decoration: none;
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: flex-end;
  }
  .report-cover-txt {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img.generic {
    margin: -20px;
  }
  &:hover { text-decoration: none; background-color: transparent !important; }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .report-subtext {
    color: $gray-600;
  }
  .report-title {
    color: $secondary;
    line-height: 1.2;
    font-weight: 600;
  }
  .ps-logo {
    text-align: center;
    margin-top: 10px;
  }
}

@mixin slide-out {
  display: block;
  position: absolute;
  z-index: 9999;
  top: 0 !important;
  bottom: 0;
  overflow: auto;
  left: unset;
  right: -100%;
  transition: all 400ms ease-out;

  &.show {
    right: 0;
    width: 100%;
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 90% }
}

/* The typewriter blinking cursor effect */
@keyframes blink-cursor {
  from, to { border-color: transparent; }
  50% { border-color: $white; }
}

/* various animations */
@keyframes ping {
  75%,to {
    transform: scale(2);
    opacity: 0
  }
}

.animate-ping-in-out {
  animation: ping 1.5s ease-in-out infinite
}

.animate-pulse-in-out {
  animation: pulse 1.5s ease-in-out infinite
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.animate-fadeIn {
  animation: fadeIn .5s ease-out forwards
}

@keyframes pulse {
  50% {
    opacity: .5;
    transform: scale(1.1)
  }

  0%,to {
    opacity: 1;
    transform: scale(1)
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite
}

